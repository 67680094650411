import React from "react"
import { Link } from "gatsby"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
// import { faCheckCircle, faChevronRight, faPlay } from '@fortawesome/free-solid-svg-icons'

export const bannerContent = {
  title: "The ultimate billing solution for your credit repair business.",
  description: [
    {
      text:
        "Start building your subscription business today from $35 a month. Our Core plan Includes $15,000 per month in transaction value, and a simple 0.4% transaction fee on overage revenue",
    },
  ],
  // withPromotion: true,
  // promotionBadge: "Offer",
  // promotionTitle: (
  //   <>
  //     <span className="bold">CreditRepairCloud</span> customers enjoy an
  //     extended $10,000 free trial
  //   </>
  // ),
  textColor: "#fff",
  backgroundColor: "#243F5C",
  svg: true,
  image: require("../images/credit-repair.svg"),
  imageName: "billsby credit repair banner image",
  imagePosition: "right",
  sectionHomeBanner: true,
  hasBg: true,
  sectionCreditRepairBanner: true,
  isBanner: true,
  buttons: [
    {
      url: "https://app.billsby.com/registration?key=0DR563TVMX",
      buttonText: "Sign up today",
      buttonColor: "orange",
      isExternal: false,
    },
    {
      scheduleButton: false,
    },
  ],
}

export const trialContent = {
  title: "Get started with Billsby today",
  description:
    <>You can sign up to Billsby for as little as $35 per month, including $15,000 per month in transaction value. <br /> The best way to get to know Billsby is to try it for yourself.</>,
  svg: true,
  image: require("../images/free-trial.svg"),
  imageName: "free-trial image",
  buttons: [
    {
      url: "https://app.billsby.com/registration?key=0DR563TVMX",
      buttonColor: "orange",
      buttonText: "Get started with your account today",
      isExternal: false,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

export const planActionContent = {
  title: "Automate your subscriptions with Zapier.",
  description: (
    <>
      Zapier integrates Billsby with more than 2,000 other products and services
      - including <span>CreditRepairCloud</span>. The result? Your customers can
      be automatically updated and data shared with the tools and systems you
      use."
    </>
  ),
  svg: true,
  image: require("../images/plan-action.svg"),
  imageName: "plan action image",
  cards: [
    {
      image: "plan-action-1.png",
      text: "Add the new customer to your CRM system",
      svgLogo: true,
      logo: require("../images/cloud.svg"),
      logoName: "cloud",
    },
    {
      image: "plan-action-2.png",
      text: "Subscribe them to the correct mailing list",
      svgLogo: true,
      logo: require("../images/active-campaign.svg"),
      logoName: "active-campaign",
    },
    {
      image: "plan-action-3.png",
      text: "Post out their welcome pack",
      logo: "shipwire.png",
      logoName: "shipwire",
    },
  ],
}

export const customerJourneyContent = {
  title: "Deliver a great experience throughout the customer journey.",
  list: [
    {
      title: "Get customers signed up",
      description: (
        <>
          Our{" "}
          <Link to="/product/subscribers/checkout-and-payments">
            drop-in checkout
          </Link>{" "}
          helps customers to sign up for your product quickly and easily, with
          low development effort.
        </>
      ),
    },
    {
      title: "Self-service account management",
      description: (
        <>
          Customers can manage their own account with our{" "}
          <Link to="/product/subscribers/account-management">
            drop-in account management tools
          </Link>{" "}
          - reducing service costs.
        </>
      ),
    },
    {
      title: "Keep customers from leaving",
      description: (
        <>
          When your customers think about leaving, our{" "}
          <Link to="/product/subscribers/dunning-retention">
            retention and promotion tools
          </Link>{" "}
          can stop them and help reduce churn.
        </>
      ),
    },
  ],
  svg: true,
  image: require("../images/customer-journey2.svg"),
  imageName: "customer journey",
}

export const authorizeContent = {
  title: "Works alongside your existing merchant account.",
  description: [
    {
      text:
        "Billsby works alongside your existing merchant account through Authorize.net so that you can operate your business in a safe, secure and legally compliant manner. All of your cards are stored in the Billsby Vault so you don't need to worry about data security.",
    },
  ],
  sectionDevelopers: true,
  image: require("../images/home-developers@2x.png"),
  svg: true,
  imageName: "developers svg",
  backgroundColor: "#f3f5fb",
  textColor: "#000",
  imagePosition: "right",
  authorize: require("../images/authorize-net-credit@2x.png"),
  authorizeIsClickable: false,
}

export const businessGrowth = {
  title: "As your business grows in complexity, we'll grow with you.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Support for pay-per-delete billing",
      description: (
        <>
          Using our metered allowances, you can charge for each delete, quickly
          updating data manually as deletions happen, or connecting with our API
          to automate this billing process. We’ll consolidate all of the charges
          and put them through for you at the end of the month.
        </>
      ),
    },
    {
      title: "Support for one-time charges",
      description: (
        <>
          If you sell additional products for a one-time fee, you can run your
          subscribers cards at any time to charge the extra. It’ll be
          automatically and immediately invoiced and taken from their account.
          No further manual action or intervention will be required on your
          part.
        </>
      ),
    },
  ],
  image: require("../images/addons-allowances.svg"),
  imageName: "billsby-business-growth",
  svg: true,
  btnClass: "adoric_video_featuretags",
}

export const noWebsite = {
  title: "No website? No problem. We've got your back.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Use Billsby Pages to create beautiful landing pages",
      description: (
        <>
          Using our free page builder, you can quickly spin up a custom landing
          page where customers can both sign up and manage their account online.
          It’s really simple and only takes a few minutes - and you can change
          and update it whenever you want.
        </>
      ),
    },
    {
      title: "Or embed Billsby into your existing website",
      description: (
        <>
          Embedding the Billsby Checkout and account management tools into your
          website takes only a couple of lines of code. It’s really simple and
          we have setup guides for lots of website builders and plugins for many
          others. Plus, our success team can step in if you get stuck.
        </>
      ),
    },
  ],
  align: "right",
  svg: true,
  image: require("../images/resume-credit.svg"),
  imageName: "addons allowances",
  btnClass: "adoric_video_allowances",
}

export const discounts = {
  title: "Discounts, coupons, failed payments. All handled.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Create discounts and coupons for edge case scenarios",
      description: (
        <>
          A customer wants to pay a few months in cash? No problem. Just
          generate a 100% discount code for their account for the length of
          their pre-payment. Want to run specials and offers? All fully
          supported. Billsby gives you the ultimate commercial flexibility.
        </>
      ),
    },
    {
      title: "Stop chasing failed payments",
      description: (
        <>
          When payments fail, we’ll automatically handle dunning workflows, give
          customers the opportunity to change their payment card and send out
          reminders based on the parameters you set. So you can worry less about
          the finances, and more about servicing your clients.
        </>
      ),
    },
  ],
  svg: true,
  image: require("../images/addon-allowance-1.svg"),
  imageName: "billsby-order-hierarchy",
  btnClass: "adoric_video_products",
}
